import React from 'react'
import tw from 'twin.macro'

const Scan = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple mb-1`} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M37.6 24.3V34.5C37.6 34.9509 37.4209 35.3833 37.1021 35.7021C36.7833 36.0209 36.3509 36.2 35.9 36.2C35.4491 36.2 35.0167 36.0209 34.6979 35.7021C34.3791 35.3833 34.2 34.9509 34.2 34.5V10.7C34.2 10.2491 34.0209 9.81673 33.7021 9.49792C33.3833 9.17911 32.9509 9 32.5 9H8.7C8.24913 9 7.81673 9.17911 7.49792 9.49792C7.17911 9.81673 7 10.2491 7 10.7V34.5C7 37.3118 9.2882 39.6 12.1 39.6H35.9C38.7118 39.6 41 37.3118 41 34.5V24.3H37.6ZM27.4 22.6V26H13.8V22.6H27.4ZM13.8 19.2V15.8H27.4V19.2H13.8ZM27.4 29.4V32.8H22.3V29.4H27.4Z"
            fill="currentColor"
        />
    </svg>
)

export default Scan
