import React from 'react'

const Calendar = () => (
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.225 6C11.8123 6 11.4165 6.16396 11.1246 6.45582C10.8327 6.74767 10.6688 7.14351 10.6688 7.55626V9.11252H9.11252C8.28703 9.11252 7.49535 9.44044 6.91164 10.0242C6.32792 10.6079 6 11.3995 6 12.225V27.7876C6 28.6131 6.32792 29.4048 6.91164 29.9885C7.49535 30.5722 8.28703 30.9001 9.11252 30.9001H27.7876C28.6131 30.9001 29.4048 30.5722 29.9885 29.9885C30.5722 29.4048 30.9001 28.6131 30.9001 27.7876V12.225C30.9001 11.3995 30.5722 10.6079 29.9885 10.0242C29.4048 9.44044 28.6131 9.11252 27.7876 9.11252H26.2314V7.55626C26.2314 7.14351 26.0674 6.74767 25.7756 6.45582C25.4837 6.16396 25.0879 6 24.6751 6C24.2624 6 23.8665 6.16396 23.5747 6.45582C23.2828 6.74767 23.1189 7.14351 23.1189 7.55626V9.11252H13.7813V7.55626C13.7813 7.14351 13.6173 6.74767 13.3255 6.45582C13.0336 6.16396 12.6378 6 12.225 6V6ZM12.225 13.7813C11.8123 13.7813 11.4165 13.9453 11.1246 14.2371C10.8327 14.529 10.6688 14.9248 10.6688 15.3376C10.6688 15.7503 10.8327 16.1461 11.1246 16.438C11.4165 16.7299 11.8123 16.8938 12.225 16.8938H24.6751C25.0879 16.8938 25.4837 16.7299 25.7756 16.438C26.0674 16.1461 26.2314 15.7503 26.2314 15.3376C26.2314 14.9248 26.0674 14.529 25.7756 14.2371C25.4837 13.9453 25.0879 13.7813 24.6751 13.7813H12.225Z"
            fill="currentColor"
        />
    </svg>
)

export default Calendar
