import React from 'react'
import tw from 'twin.macro'

const Directory = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple mb-1`} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.80005 14.4C4.80005 13.1269 5.30576 11.906 6.20594 11.0059C7.10611 10.1057 8.32701 9.59998 9.60005 9.59998H19.2L24 14.4H33.6C34.8731 14.4 36.094 14.9057 36.9942 15.8059C37.8943 16.706 38.4001 17.9269 38.4001 19.2V21.6H19.2C17.2905 21.6 15.4591 22.3585 14.1089 23.7088C12.7586 25.0591 12 26.8904 12 28.8V32.4C12 33.3548 11.6208 34.2704 10.9456 34.9456C10.2705 35.6207 9.35483 36 8.40005 36C7.44527 36 6.5296 35.6207 5.85446 34.9456C5.17933 34.2704 4.80005 33.3548 4.80005 32.4V14.4Z"
            fill="currentColor"
        />
        <path
            d="M14.4 28.8C14.4 27.527 14.9058 26.3061 15.8059 25.4059C16.7061 24.5057 17.927 24 19.2 24H38.4C39.6731 24 40.894 24.5057 41.7942 25.4059C42.6943 26.3061 43.2 27.527 43.2 28.8V33.6C43.2 34.873 42.6943 36.0939 41.7942 36.9941C40.894 37.8943 39.6731 38.4 38.4 38.4H4.80005H9.60005C10.8731 38.4 12.094 37.8943 12.9942 36.9941C13.8943 36.0939 14.4 34.873 14.4 33.6V28.8Z"
            fill="currentColor"
        />
    </svg>
)

export default Directory
