/* eslint-disable no-nested-ternary */
import React from 'react'
import tw from 'twin.macro'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColouredBand, ColoredBackgroundWithProps } from '../components/shared/landingPages/layoutComponents'
import Header from '../components/shared/landingPages/header'
import TopHighlights from '../components/shared/landingPages/topHighlights'
import HighlightedFunctionality from '../components/shared/landingPages/highlightedFunctionalityOld'
import AdvancedFunctionality from '../components/shared/landingPages/advancedFunctionality'
import FinalCTA from '../components/shared/landingPages/finalCTA'
import Pages from '../images/boekhoudinglp/pages'
import Scan from '../images/boekhoudinglp/scan'
import Directory from '../images/boekhoudinglp/directory'
import Calendar from '../images/boekhoudinglp/calendar'
import headerPng from '../images/boekhoudinglp/headerImage.png'
import accountingPng from '../images/boekhoudinglp/accounting.png'
import presentationPng from '../images/boekhoudinglp/presentation.png'
import vatPng from '../images/boekhoudinglp/vat.png'
import lastPng from '../images/boekhoudinglp/lastImage.png'

const headerData = {
    announcer: `Dé online boekhoudtool voor zzp’ers`,
    title: `Alles wat je nodig hebt voor je boekhouding`,
    text: `Scan bonnen, verstuur facturen en regel eenvoudig je zaken bij de Belastingdienst: Tellow maakt boekhouden overzichtelijk.`,
    textCTA: `Gratis aanmelden`,
    headerImage: headerPng,
}

const topHighlightData = {
    title: `Je boekhouding regelen met Tellow`,
    points: [
        {
            Icon: Pages,
            title: `Factureren`,
            body: `
            Maak facturen in jouw huisstijl en stuur deze direct naar je klant. Zodra er is betaald krijg je een melding.
        `,
        },
        {
            Icon: Scan,
            title: `Bonnen scannen`,
            body: `
            Scan je bon in één klik met jouw mobiel. Tellow leest de bon uit en doet een boekingsvoorstel.
        `,
        },
        {
            Icon: Directory,
            title: `Volledig overzicht`,
            body: `
            Automatisch opgestelde en bijgewerkte rapportages zorgen ervoor dat je altijd en overal inzicht hebt in je financiën en onderneming.
        `,
        },
        {
            Icon: Calendar,
            title: `Altijd up to date`,
            body: `
            Met de automatische bankkoppeling worden je transacties automatisch ingeladen. Als er nog iets moet gebeuren sturen we je een herinnering.
        `,
        },
    ],
}

const highlightedFunctionalityData = {
    title: `Regel je zaken met Tellow`,
    firstPoint: `Offertes`,
    firstDescription: `Stel offertes op en verstuur deze direct naar de klant. Als de offerte geaccepteerd is kan je deze meteen omzetten in een factuur.`,
    secondPoint: `Snel je geld ontvangen`,
    secondDescription: `Tellow laat je zien welke facturen wel en niet betaald zijn. Ook helpen wij je bijhouden of er nog een herinnering verstuurd kan worden. Zo loop je nooit geld mis!`,
    firstStepImage: accountingPng,
}

const advancedFunctionalityData = {
    firstAnnouncer: `Persoonlijk advies`,
    firstDescription: `Krijg de hele week gratis ondersteuning via chat, e-mail en telefoon. Onze adviseurs staan voor je klaar om je verder te helpen met al je vragen over boekhouden met Tellow.`,
    getMoneyOneImage: presentationPng,
    secondAnnouncer: `Btw-aangifte`,
    secondDescription: `Tellow houdt automatisch je belastingzaken bij. Zodra je btw-aangifte wilt doen staat deze ingevuld voor je klaar. Met een druk op de knop is het geregeld met de Belastingdienst.`,
    getMoneyTwoImage: vatPng,
}

const finalCTAData = {
    title: `Twijfel je nog?`,
    text: `We hebben goed nieuws voor je! Je kan onze boekhoudtool nu helemaal gratis gebruiken. Tellow Gratis is speciaal ingericht voor zzp’ers en geschikt voor iedereen die boekhouden simpel en gemakkelijk wilt houden.`,
    finalImage: lastPng,
}

const Boekhouding = () => (
    <Layout>
        {/* Page not indexed */}
        <Meta
            name="robots"
            indexed="noindex"
            path="/boekhouding"
            title="Boekhouding | Tellow.nl"
            description="Snel online boekhouden met hét online boekhoudprogramma voor zzp'ers ✅ 100.000+ ondernemers gingen je voor ✅ Registreer gratis!"
        />
        {/* First Component : Header (Main title and image) */}
        <RelativeContainer>
            <ComponentContainer>
                <Header data={headerData} />
            </ComponentContainer>
        </RelativeContainer>

        {/* Purple band  */}
        <ColouredBand purple />

        {/* Second Component : Quotes Step (4 points) */}
        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Third Component : First Quote (with Design) */}
        <RelativeContainer>
            <RelativeContainer>
                <ComponentContainer>
                    <HighlightedFunctionality data={highlightedFunctionalityData} />
                </ComponentContainer>
            </RelativeContainer>
        </RelativeContainer>

        {/* Fourth Component : Get Money (I've got a lot of work to do) */}
        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <AdvancedFunctionality data={advancedFunctionalityData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Purple band */}
        <ColouredBand purple />

        {/* Fifth Component : Doubt? */}
        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer css={tw`my-0`}>
                    <FinalCTA data={finalCTAData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>
    </Layout>
)

export default Boekhouding
